import _ from "lodash/fp";
import React from "react";
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { defaultStructureColumns } from "@truedat/dd/selectors";

const defaultStructuresColumnsFiltered = _.filter(
  _.propEq("name", "relation.relation_type_name")
)(defaultStructureColumns);

const dateDecorator = date => (
  <Moment locale="es" date={date} format="YYYY-MM-DD HH:mm" />
);

const translateDecorator = text => <FormattedMessage id={text} />;

const pathDecorator = path => (
  <span title={_.join(" › ")(path)}>
    {_.flow(_.join(" › "), _.truncate({ length: 90 }))(path)}{" "}
  </span>
);
const nameFieldSelector = ({ name, note }) =>
  _.isEmpty(note?.alias) ? name : note.alias;

const initialState = [
  {
    name: "name",
    fieldSelector: nameFieldSelector,
    sort: { name: "name.sort" }
  },
  {
    name: "type",
    fieldDecorator: type => translateDecorator(`structure.type.${type}.text`)
  },
  {
    name: "system",
    fieldSelector: _.path("system.name"),
    width: 1,
    sort: { name: "system.name.raw" }
  },
  {
    name: "path",
    fieldDecorator: pathDecorator,
    sort: { name: "path_sort" }
  },
  ...defaultStructuresColumnsFiltered,
  {
    name: "updated_at",
    sort: { name: "updated_at" },
    fieldDecorator: dateDecorator,
    textAlign: "center"
  }
];

export const structuresColumns = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
