import _ from "lodash/fp";
import React from "react";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Accordion, Header, Icon, Image } from "semantic-ui-react";
import { AuthMethodsLoader, LoginButtons } from "@truedat/auth/components";
import { Alert } from "@truedat/core/components";
import { SEARCH } from "@truedat/core/routes";
import logo from "assets/logoSantalucia.png";
import LoginForm from "./LoginForm";

const LoginHeader = () => (
  <Header as="h1" icon textAlign="center">
    <Header.Content>
      <Image src={logo} />
      <FormattedMessage
        id="login.description"
        defaultMessage="Data Governance"
      />
    </Header.Content>
  </Header>
);

export class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formVisible: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e) {
    e.preventDefault();
    const { formVisible } = this.state;
    this.setState({ formVisible: !formVisible });
  }

  render() {
    const { formVisible } = this.state;
    return (
      <>
        <LoginHeader />
        <AuthMethodsLoader />
        <LoginButtons />
        <Alert />
        <Accordion>
          <Accordion.Title active={formVisible} onClick={this.handleToggle}>
            <Icon name="dropdown" />
            <FormattedMessage
              id="login.alternative"
              defaultMessage="Alternative login"
            />
          </Accordion.Title>
        </Accordion>
        {formVisible && <LoginForm />}
      </>
    );
  }
}

const resolveRoute = defaultRoute =>
  _.cond([
    [_.pathEq("state.from.pathname", "/"), () => defaultRoute],
    [_.has("state.from"), _.path("state.from")],
    [_.stubTrue, () => defaultRoute]
  ]);

const LoginOrRedirect = ({ token }) => {
  const location = useLocation();
  const currentTime = (new Date().getTime() + 1) / 1000;
  const validToken = token && jwt.decode(token).exp > currentTime;

  if (validToken) {
    const to = resolveRoute(SEARCH)(location);
    return <Redirect to={to} />;
  } else {
    const centerStyle = {
      width: "400px",
      height: "400px",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      maxWidth: "100%",
      maxHeight: "100%"
    };
    return (
      <div className="login">
        <div style={centerStyle}>
          <LoginView />
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ authentication }) => ({
  token: authentication.token
});

export default connect(mapStateToProps)(LoginOrRedirect);
