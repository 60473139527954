import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
import { createClient } from "@truedat/core/services/graphql";
import { createSwrConfig } from "@truedat/core/services/swr";
import * as reducers from "./reducers";
import makeSagas from "./sagas";

const combinedReducers = combineReducers(Object.assign({}, reducers));

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  combinedReducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// Note: the GraphQL client is defined here to avoid circular dependencies
// between the `store`, `graphql` and `sagas` modules.
export const client = createClient(store);
export const swrConfig = createSwrConfig(store);
const sagas = makeSagas(client);

sagaMiddleware.run(sagas);
