export default {
  // Concepts
  "concepts.crumbs.top": "Glosario de conceptos",
  "concepts.header": "Glosario de conceptos",
  "concepts.header.edit": "Editar concepto",

  "conceptLink.actions.delete.confirmation.content":
    "Se eliminará la vinculación entre el concepto y elemento. ¿Estás seguro?",
  "conceptLink.structure": "Elemento",
  "conceptLink.structure.placeholder": "Seleccionar elemento",
  "conceptLinks.empty":
    "Este concepto no tiene elementos vinculados actualmente",
  "conceptField.structure": "Elemento",

  "concepts.actions.create": "Nuevo concepto",
  "concepts.props.type": "Tipo",
  "concepts.props.gdpr": "GDPR",
  "concepts.props.comisario": "Comisario del dato",
  "concepts.props.ambito": "Ámbito funcional",
  "concepts.props.domain": "Ámbito funcional",
  "concepts.props.type_label": "Tipo",
  "concepts.props.link_count": "Vinculado",
  "concepts.props.rule_count": "Calidad",

  "concepts.link_count.linked_terms": "Vinculado a elemento",
  "concepts.link_count.not_linked_terms": "No vinculado a elemento",
  "conceptLink.structure": "Elemento",
  "conceptLink.ou": "Entidad",
  "conceptLink.ou.placeholder": "Seleccionar entidad",
  "conceptLinks.empty":
    "Este concepto no tiene elementos vinculados actualmente",
  "conceptRelations.relatedConcept": " ",
  "conceptRelation.ou": "Ámbito funcional",
  "conceptRelation.ou.placeholder": "Seleccionar ámbito funcional",
  "conceptRelation.structure": "Elemento",
  "conceptRelation.structure.placeholder": "Seleccionar elemento",
  "conceptRelation.table.title": "Vinculaciones con elementos",
  "masterConceptRelation.table.title": "Vínculo a elemento tipo master",
  "conceptDeletedRelation.table.title": "Elementos Eliminados",

  "conceptRelation.master.table.title": "Elemento de referencia",

  //Domains
  domain: "Ámbito funcional",
  "domain.actions.create": "Nuevo ámbito funcional",
  "domain.actions.edit.header": "Editar ámbito funcional",
  "domain.children.empty": "Este dominio no tiene ámbitos funcionales",
  "domain.error.existing.domain":
    "({text}) No se puede eliminar un dominio que contenga ámbitos funcionales",
  "domain.error.existing.business.concept":
    "({text}) No se puede eliminar un Ámbito Funcional que contenga conceptos de negocio",
  "domain.error.name.unique": "El nombre del ámbito funcional ya existe",
  "domain.members.empty": "Este ámbito funcional no tiene miembros",
  "domain.props.children": "{count} ámbitos funcionales",
  "domain.props.description.placeholder":
    "Una descripción del ámbito funcional",
  "domain.props.name.placeholder": "Nombre del ámbito funcional",
  "domain.props.type.placeholder": "Tipo del ámbito funcional",
  "domain.selector.label": "Ámbito funcional",

  "domain.selector.placeholder": "Selecciona un ámbito funcional...",
  "domains.actions.create": "Nuevo ámbito funcional",
  "domains.search.placeholder": "Buscar ámbitos funcionales...",
  "domains.search.results.count": "{count} ámbitos funcionales encontrados:",
  "domains.search.results.empty": "Ningún ámbito funcional encontrado",
  "domains.subheader":
    "Gestionar los ámbitos funcionales de negocio y gobierno de datos",

  "data_structure.search.description": "En elementos",

  //Filters BG
  "filters.comisario": "Comisario del dato",
  "filters.criticidad": "Criticidad",
  "filters.gdpr": "GDPR",
  "filters.motivo_criticidad": "Motivo criticidad",
  "filters.periodicidad": "Periodicidad",
  "filters.resp_tecnico": "Responsable técnico",
  "filters.soporte": "Soporte tecnológico",
  "filters.tipo_aplicacion": "Tipo de aplicación",
  "filters.tipo_dato": "Tipo de dato",
  "filters.domain": "Ámbito funcional",
  "filters.ou.raw": "Ámbito funcional",
  "filters.domain_parents": "Ámbito funcional",
  "filters.dominio_informacion": "Dominio información de gestión",
  "filters.fase1": "DWH Fase I",
  "filters.idioma": "Idioma",
  "filters.naturaleza": "Naturaleza del dato",
  "filters.pais": "País",
  "filters.sensible": "Sensible",
  "filters.sujeto_gdpr": "Sujeto a GDPR",
  "filters.temporalidad": "Temporalidad",
  "filters.tipo": "Tipo de término",
  "filters.type.raw": "Tipo",
  "filters._confidential": "Confidencial",
  "filters.has_links": "Vinculación",
  "filters.Criterio_calidad": "Criterio de calidad",
  "filters.Criticidad": "Criticidad",
  "filters.Periodicidad_ejecución": "Periodo de ejecución",
  "filters.field_type.raw": "Tipo de dato",
  "filters.taxonomy": "Ámbito funcional",

  //Filters DD
  "filters.class.raw": "Clase",
  "filters.class.raw.field": "Campo",

  "roles.create": "Crear nuevo rol",
  "roles.actions.delete.confirmation.header": "Eliminar Rol",
  "roles.actions.delete.confirmation.content":
    "Se va a eliminar el rol {name}. ¿Estás seguro?",

  //Implementations
  "ruleImplementation.freetext.label":
    "Seleccionar un elemento mediante texto libre",

  "ruleImplementation.props.campo": "Campo",
  "ruleImplementation.props.campo.placeholder": "Selecciona el campo",

  "ruleImplementation.props.dato_maestro": "Campo maestro",
  "ruleImplementation.props.dato_maestro.placeholder":
    "Selecciona el campo maestro",

  "ruleImplementation.props.dato_relacionado": "Campo relacionado",
  "ruleImplementation.props.dato_relacionado.placeholder":
    "Selecciona el campo relacionado",
  "ruleImplementations.props.dataset": "Estructura",
  "ruleImplementations.props.inserted_at": "Creado",
  "ruleImplementation.operator.eq_var.": "es igual a variable",
  "ruleImplementation.operator.eq_var.string": "es igual a variable",
  "ruleImplementation.operator.eq_var.date": "es igual a variable",
  "ruleImplementation.operator.eq_var.field": "es igual a variable",
  "ruleImplementation.operator.eq_var.number": "es igual a variable",
  "ruleImplementation.operator.eq_var.timestamp": "es igual a variable",
  "ruleImplementation.operator.gt_var": "es mayor que variable",
  "ruleImplementation.operator.gt_var.string": "es mayor que variable",
  "ruleImplementation.operator.gt_var.date": "mayor o igual que",
  "ruleImplementation.operator.gt_var.field": "mayor o igual que el campo",
  "ruleImplementation.operator.gt_var.number": "mayor o igual que",
  "ruleImplementation.operator.gt_var.timestamp": "mayor o igual que",
  "ruleImplementation.operator.lt_var": "menor o igual que",
  "ruleImplementation.operator.lt_var.string": "es menor que variable",
  "ruleImplementation.operator.lt_var.date": "menor o igual que",
  "ruleImplementation.operator.lt_var.field": "menor o igual que el campo",
  "ruleImplementation.operator.lt_var.number": "menor o igual que",
  "ruleImplementation.operator.lt_var.timestamp": "menor o igual que",

  "ruleImplementation.operator.not_eq_var.string": "no es igual a la variable",
  "ruleImplementation.operator.gte_var": "mayor o igual que variable",
  "ruleImplementation.operator.gte_var.string":
    "mayor o igual que la variable variable",

  "ruleImplementation.filtersField.D-0": "Hoy",
  "ruleImplementation.filtersField.D-1": "Ayer",
  "ruleImplementation.filtersField.M-0": "Primer día de este mes",
  "ruleImplementation.filtersField.M-1": "Últmo día del mes anterior",
  "ruleImplementation.filtersField.M-2": "Último día de hace dos meses",
  "ruleImplementation.filtersField.Y-0": "Primer día de este año",
  "ruleImplementation.filtersField.Y-1": "Último día del año anterior",

  "ruleImplementation.operator.lt.date": "menor que",

  "ruleImplementation.operator.meets_criteria.string": "cumple que",
  "ruleImplementation.operator.meets_criteria": "cumple que",

  "ruleImplementation.filtersField.last_period": "Último periodo de validación",

  "ruleImplementation.result.evidences": "Copiar Link Evidencias",

  // Rules
  "rule.min_number": "Valor mínimo",
  "rule.max_number": "Valor máximo",
  "rule.values_list": "Listado de valores no admitidos",

  "rule.type.comparacion_magnitudes": "Comparación entre magnitudes",
  "rule.type.datos_ausentes": "Datos ausentes",
  "rule.type.duplicidad": "Duplicidad de información",
  "rule.type.formato_valido": "Formato válido",
  "rule.type.integridad_datos_maestros": "Integridad entre datos maestros",
  "rule.type.integridad_datos_relacionados":
    "Integridad entre datos relacionados",
  "rule.type.lista_valores": "Distinto de un valor o conjunto de valores",
  "rule.type.rango_fechas": "Rango de fechas",
  "rule.type.rango_numericos": "Rango de valores numéricos",

  //Quality
  "quality.header": "Catálogo de controles de calidad",
  "quality.subheader":
    "Gestionar los controles de calidad y sus implementaciones",
  "quality.current": "Resultado de la última ejecución",
  "quality.result_avg": "Resultado (%)",

  "ruleImplementation.props.executable.false": "Externa",
  "ruleImplementations.props.executable.false": "Externa",

  // Rule Result
  "ruleResult.props.records": "Volumen total",
  "ruleResult.props.errors": "Volumen erróneo",
  "ruleResult.props.cbloqfun": "Bloque funcional",
  "ruleResult.props.cmesano": "Periodo de datos",
  "ruleResult.props.date": "Fecha de ejecución",

  // Structures
  "structures.crumbs.top": "Elementos",
  "structures.header": "Elementos de información",
  "structures.search.placeholder": "Buscar elementos...",
  "structures.subheader":
    "Elementos de información: documentos, informes, ficheros y tablas",

  "structures.search.description": "En elementos",

  //Structure
  "structure.field.links": "Conceptos",
  "structure.ou": "Ámbito",
  "structure.ous": "Ámbitos",
  "structure.last_change_at": "Última modificación",
  "structure.Host": "Mi label host",
  "structure.filename": "Último fichero",
  "structure.last_modified": "Última modificación",
  "structure.size": "Tamaño fichero",
  "structure.relation.name": "Elemento",
  "structure.relation.links": "Conceptos",
  "structure.relation.relation_type": "Relación",
  "structure.relation.structure_type": "Tipo de estructura",
  "structure.name": "Elemento",
  "structure.propietario": "Propietario",
  "structure.tipo": "Tipo",
  "structure.field.links": "Conceptos",
  "structure.field.primary_key": "Clave Primaria",
  "structure.field.length": "Longitud",
  "structure.fields.empty":
    "No se dispone de información sobre los campos de este elemento",

  //Structure types
  "structure.type.Column": "Campo",
  "structure.type.Column.text": "Campo",
  "structure.type.Column.icon": "columns",
  "structure.type.undefined": " ",

  "structure.type.Schema": "Esquema",
  "structure.type.Schema.text": "Esquema",
  "structure.type.Schema.icon": "sitemap",

  "structure.type.Submodel": "Submodelo",
  "structure.type.Submodel.text": "Submodelo",
  "structure.type.Submodel.icon": "window restore",

  "structure.type.TABLE": "Tabla",
  "structure.type.TABLE.text": "Tabla",
  "structure.type.TABLE.icon": "table",

  "structure.type.Table": "Tabla",
  "structure.type.Table.text": "Tabla",
  "structure.type.Table.icon": "table",

  "structure.type.DATA": "Tabla SAS",
  "structure.type.DATA.text": "Tabla SAS",
  "structure.type.DATA.icon": "table",

  "structure.type.Field": "Campo",
  "structure.type.Field.text": "Campo",
  "structure.type.Field.icon": "columns",

  "structure.type.Report": "Informe",
  "structure.type.Report.text": "Informe",
  "structure.type.Report.icon": "chart bar",

  "structure.type.Calculated Table": "Tabla calculada",
  "structure.type.Calculated Table.text": "Tabla calculada",
  "structure.type.Calculated Table.icon": "tasks",

  "structure.type.Workspace": "Área de trabajo",
  "structure.type.Workspace.text": "Área de trabajo",
  "structure.type.Workspace.icon": "folder",

  "structure.type.worksheet": "Hoja de trabajo",
  "structure.type.worksheet.text": "Hoja de trabajo",
  "structure.type.worksheet.icon": "file alternate",

  "structure.type.Worksheet": "Hoja de trabajo",
  "structure.type.Worksheet.text": "Hoja de trabajo",
  "structure.type.Worksheet.icon": "file alternate",

  "structure.type.Measure": "Métrica",
  "structure.type.Measure.text": "Métrica",
  "structure.type.Measure.icon": "tachometer alternate",

  "structure.type.Calculated Column": "Columna calculada",
  "structure.type.Calculated Column.text": "Columna calculada",
  "structure.type.Calculated Column.icon": "calculator",

  "structure.type.Document": "Documento",
  "structure.type.Document.text": "Documento",
  "structure.type.Document.icon": "file image",

  "structure.type.Folder": "Carpeta",
  "structure.type.Folder.text": "Carpeta",
  "structure.type.Folder.icon": "folder",

  "structure.type.file_folder": "Carpeta",
  "structure.type.file_folder.text": "Carpeta",
  "structure.type.file_folder.icon": "folder",

  "structure.type.Metric": "Métrica",
  "structure.type.Metric.text": "Métrica",
  "structure.type.Metric.icon": "tachometer alternate",

  "structure.type.Attribute": "Dimensión",
  "structure.type.Attribute.text": "Dimensión",
  "structure.type.Attribute.icon": "cube",

  "structure.type.Project": "Proyecto",
  "structure.type.Project.text": "Proyecto",
  "structure.type.Project.icon": "globe",

  "structure.type.File": "Fichero",
  "structure.type.File.text": "Fichero",
  "structure.type.File.icon": "file",

  "structure.type.libname": "Librería",
  "structure.type.libname.text": "Librería",
  "structure.type.libname.icon": "globe",

  "structure.type.Model": "Modelo",
  "structure.type.Model.text": "Modelo",
  "structure.type.Model.icon": "globe",

  "structure.type.VIEW": "Vista",
  "structure.type.VIEW.text": "Vista",
  "structure.type.VIEW.icon": "eye",

  "structure.type.USER_TABLE": "Tabla",
  "structure.type.USER_TABLE.text": "Tabla",
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.lineage-field.icon": "columns",
  "structure.type.project.icon": "globe",
  "structure.type.schema.icon": "sitemap",
  "structure.type.lineage-table.icon": "table",
  "structure.type.lineage-load.icon": "sitemap",
  "structure.type.SESSION.icon": "cog",
  "structure.type.trasformation-field.icon": "columns",
  "structure.type.WORKFLOW.icon": "cogs",

  "structure.type.Database": "Base de datos",
  "structure.type.Database.icon": "database",
  "structure.type.Database.text": "Base de datos",

  "structure.type.Calculated Table Column": "Columna de tabla calculada",
  "structure.type.Calculated Table Column.icon": "calculator",
  "structure.type.Calculated Table Column.text": "Columna de tabla calculada",

  "structure.type.measure": "Medida",
  "structure.type.measure.icon": "tachometer alternate",
  "structure.type.measure.text": "Medida",

  "structure.type.CHAR.icon": "font",
  "structure.type.char.icon": "font",
  "structure.type.VARCHAR2.icon": "font",
  "structure.type.CLOB.icon": "font",
  "structure.type.DATE.icon": "calendar alternate outline",
  "structure.type.TIMESTAMP(5).icon": "clock outline",
  "structure.type.TIMESTAMP(6).icon": "clock outline",
  "structure.type.FLOAT.icon": "hashtag",
  "structure.type.NUMBER.icon": "hashtag",
  "structure.type.num.icon": "hashtag",
  "structure.type.nvarchar.icon": "font",
  "structure.type.RAW.icon": "file code",
  "structure.type.varchar.icon": "font",
  "structure.type.smallint.icon": "hashtag",
  "structure.type.datetime2.icon": "calendar alternate outline",
  "structure.type.TIMESTAMP.icon": "clock outline",
  "structure.type.dateTime.icon": "clock outline",
  "structure.type.datetime.icon": "clock outline",
  "structure.type.DATE.icon": "calendar alternate outline",
  "structure.type.INT.icon": "hashtag",
  "structure.type.BIGINT.icon": "hashtag",
  "structure.type.DECIMAL.icon": "hashtag",
  "structure.type.DOUBLE.icon": "hashtag",
  "structure.type.FLOAT.icon": "hashtag",
  "structure.type.int64.icon": "hashtag",
  "structure.type.STRING.icon": "font",
  "structure.type.MAP.icon": "object group outline",
  "structure.type.STRUCT.icon": "object group outline",
  "structure.type.variant.icon": "object ungroup outline",

  //Relations Structures
  "relation.type.from.calculated": "Se calcula por",
  "relation.type.to.calculated": "Se usa para calcular",

  "relation.type.from.includes": "Padre de",
  "relation.type.to.includes": "Pertenece",

  "relation.type.includes": "Incluído",
  "relation.type.calculated": "Usado para el cálculo",

  //Lineage
  "lineage.form.header": "Seleccionar Elementos",
  "lineage.group.placeholder": "Seleccionar Elemento Padre",
  "lineage.resources.placeholder": "Seleccionar Elementos Hijos",
  "lineage.subgroup.placeholder": "Seleccionar Elemento Hijo",

  "tabs.bg.link_manager": "Vinculación",
  "tabs.bg.audit": "Auditoría",
  "tabs.bg.qualityRules": "Controles de calidad",
  "tabs.dd.fields": "Elemento",
  "tabs.structures": "Elementos",
  "tabs.subdomains": "Ámbitos funcionales",
  "tabs.dd.extra_info": "Información adicional",
  "structure.field.has_df_content": "Información adicional",
  "tabs.dd.audit": "Auditoría",
  "tabs.se.structures": "Elementos",
  "tabs.dd.lineage": "Trazabilidad",

  "templates.tabs.bg": "Glosario de conceptos",
  "templates.tabs.dd": "Catálogo de elementos",

  "user.acl.domain": "Ámbito funcional",

  "visualization.title.lineage": "Análisis de trazabilidad",

  //Login
  "login.alternative": "Acceso alternativo",
  "login.description": "Data Governance",
  "login.form.actions.saml_idp.login": "Acceso Corporativo",
  "login.form.username.placeholder": "fulanito@santalucia.es",

  //Navigation
  "navigation.glossary.concepts": "Glosario",
  "navigation.glossary.taxonomy": "Ámbitos",
  "navigation.dictionary": "Elementos",
  "navigation.dictionary.structures": "Elementos",
  "navigation.lineage": "Trazabilidad",
  "navigation.lineage.search": "Trazabilidad",
  "navigation.admin.users": "Usuarios",
  "navigation.admin.roles": "Roles",
  "navigation.admin.templates": "Plantillas",
  "navigation.quality.rules": "Calidad",

  "sidemenu.catalog": "Elementos",
  "sidemenu.dashboard": "Dashboard",
  "sidemenu.jobs": "Jobs",
  "sidemenu.lineage": "Trazabilidad",
  "sidemenu.quality": "Calidad",
  "sidemenu.roles": "Roles",
  "sidemenu.structures": "Elementos",
  "sidemenu.relations": "Relaciones",
  "sidemenu.taxonomy": "Ámbitos",

  //Link Manager
  "source.bc_superior": "Concepto superior de",
  "target.bc_superior": "Concepto inferior de",
  "source.bc_calculado": "Calculado a partir de",
  "target.bc_calculado": "Utilizado para calcular",

  "visualization.dictionary.no_structure": "No existe elemento relacionado",

  //ACL
  "permission.group.taxonomy_membership": "Asignación de roles en taxonomía",
  "permission.group.taxonomy": "Taxonomía",
  "permission.group.business_glossary": "Glosario",
  "permission.group.data_dictionary": "Elementos",
  "permission.group.data_quality": "Calidad",
  "permission.group.undefined": "Permisos de Aplicación",

  "permission.create_acl_entry":
    "Asignar rol a un usuario/grupo en el ámbito funcional",
  "permission.create_data_structure": "Crear elementos de información",
  "permission.create_domain": "Crear ámbitos funcionales",
  "permission.delete_acl_entry":
    "Borrar rol de un usuario/grupo en un ámbito funcional",
  "permission.delete_data_structure": "Borrar elementos de información",
  "permission.delete_domain": "Borrar ámbitos funcionales",
  "permission.update_acl_entry":
    "Modificar rol de un usuario/grupo en un ámbito funcional",
  "permission.update_data_structure": "Editar elementos de información",
  "permission.update_domain": "Modificar ámbitos funcionales",
  "permission.view_data_structure": "Ver elementos de información",
  "permission.view_domain": "Ver ámbitos funcionales",
  "permission.manage_quality_rule": "Gestionar controles de calidad",
  "permission.link_data_structure":
    "Vincular elementos a controles de calidad, conceptos, etc.",
  "permission.manage_confidential_structures":
    "Gestionar elementos confidenciales",

  "permission.view_data_structures_profile": "Ver perfilado de elementos",

  //App Setup
  "appSetup.welcome.message": "Bienvenido a Truedat",
  "appSetup.configuration.message":
    "Parece que todo se ha instalado correctamente. Vamos a comenzar a configurar la aplicación para poder empezar a usarla tras unos sencillos pasos",
  "appSetup.begin.button": "Comenzar",

  // Missing from td-web-modules
  "ruleImplementation.summary.structure_alias": "Source",
  "ruleImplementation.summary.database": "Database",

  //Control de calidad
  "subscriptions.resource.type.rule": "Control de Calidad",
  "subscriptions.events.rule_created": "Control de calidad creada",
  "subscriptions.events.rule_result_created":
    "Resultado de control de calidad creado",
  "permission.group.quality_rules": "Gestión de Controles de Calidad",
  "permission.group.quality_view": "Visualización de Controles de Calidad",
  "permission.link_data_structure":
    "Vincular estructuras a controles de calidad, conceptos, etc.",
  "permission.manage_quality_rule": "Gestionar controles de calidad",
  "permission.manage_ruleless_implementations":
    "Gestionar implementaciones sin control de calidad",
  "concepts.actions.delete.body.without_content":
    "Vas a borrar este concepto que no tiene vinculaciones ni conceptos relacionados ni controles de calidad",
  "concepts.actions.execute": "Ejecutar controles de calidad",
  "concepts.props.rule_count": "Controles de calidad",
  "concepts.rule.create": "Crear control de calidad",
  "filters.has_rules": "Controles de calidad",
  "filters.has_rules.false": "Sin controles de calidad",
  "filters.has_rules.true": "Con controles de calidad",
  "tabs.bg.qualityRules": "Controles de Calidad",
  "sidemenu.rules": "Controles de Calidad",
  "tabs.dd.rules": "Controles de calidad",
  "template.scope.dq": "Control de calidad",
  "alert.createRule.failed.header": "Error creando control de calidad",
  "alert.deleteRule.failed.header": "Error borrando control de calidad",
  "alert.updateRule.failed.header": "Error actualizando control de calidad",
  "concepts.rules.empty": "Ese concepto no tiene controles de calidad",
  "createRule.error.rule_name_bc_id.unique_constraint":
    "El control de calidad ya existe con ese nombre o concepto asociado",
  "filters.rule": "Control de calidad",
  "navigation.quality.rules": "Controles de Calidad",
  "quality.actions.create": "Nuevo control de calidad",
  "quality.actions.edit": "Editar control de calidad",
  "quality.actions.remove": "Borrar control de calidad",
  "rule.error.rule_implementations.rule.delete.existing.implementations":
    "Todas las implementaciones de un control de calidad deben ser eliminadas",
  "rule.error.rule_name_bc_id.unique_constraint":
    "El control de calidad ya existe con ese nombre y concepto asociado",
  "rule.form.tooltip.type":
    "Define el control de calidad que se quiere ejecutar sobre el dato. Para ciertos tipos de control de calidad será necesario introducir parámetros del control de calidad",
  "rule.props.description.placeholder": "Descripción del control de calidad",
  "rule.props.name.placeholder": "Nombre del control de calidad",
  "rule.props.type": "Tipo de control de calidad",
  "rule.ruleImplementations.empty":
    "No existen controles de calidad de implementación de este control de calidad",
  rule: "Control de Calidad",
  "ruleImplementation.actions.delete.confirmation.content":
    "Se eliminará la implementación de control de calidad. ¿Estás seguro?",
  "ruleImplementation.actions.move": "Mover a otro control de calidad",
  "ruleImplementation.form.tooltip.deviation.goal":
    "Porcentaje máximo de registros erróneos para dar como buena la ejecución de este control de calidad (mejor cuanto más bajo)",
  "ruleImplementation.form.tooltip.errors_number.goal":
    "Número máximo de registros erróneos para dar como buena la ejecución de este control de calidad (mejor cuanto más bajo)",
  "ruleImplementation.form.tooltip.percentage.goal":
    "Porcentaje mínimo de registros correctos para dar como buena la ejecución de este control de calidad (mejor cuanto más alto)",
  "ruleImplementation.props.type.placeholder": "Tipo de control de calidad",
  "ruleImplementations.events.action_moved":
    "Implementación movida al control de calidad: {0}",
  "ruleImplementations.props.rule": "Control de calidad",
  "ruleImplementations.props.rule_template": "Plantilla de control de calidad",
  "ruleImplementations.summary.headers.implementation":
    "Implementación de un Control de calidad",
  ruleResult: "Resultado de ejecución de control de calidad",
  "rules.actions.create": "Nuevo control de calidad",
  "rules.actions.delete.confirmation.content":
    "Se va a eliminar el control de calidad {name}. ¿Está seguro?",
  "rules.actions.delete.confirmation.header": "Eliminar control de calidad",
  "rules.actions.upload.confirmation.header":
    "Subir ficheros de controles de calidad",
  "rules.actions.upload.tooltip": "Subir controles de calidad",
  "rules.crumbs.top": "Controles de Calidad",
  "rules.events.action_created": "Control de calidad creado",
  "rules.events.action_updated": "Control de calidad actualizado",
  "ruleImplementations.events.action_moved":
    "Implementación movida al control de calidad: {0}",
  "rules.retrieved.results": "{count} Controles de calidad encontrados",
  "rules.search.placeholder": "Buscar controles de calidad...",
  "tabs.dq.rule": "Control de calidad",
  "tabs.qualityRules": "Controles de Calidad",
  "ingests.actions.execute": "Ejecutar controles de calidad",
  "rule.props.type": "Tipo de control de calidad",
  "ruleImplementation.props.type.placeholder": "Tipo de control de calidad",
  //Notes
  "structures.uploadStructures.failed.note.only_draft_are_editable":
    "La información adicional de la fila {row} debe estar en estado borrador para poder ser editada",
  "structure.notes.create": "Editar información adicional",
  "structure.notes.edit": "Editar información adicional",
  "structure.notes.empty": "No hay información adicional",
  "structure.notes.actions.deleted.confirmation.header":
    "Eliminar información adicional",
  "structure.notes.actions.deleted.confirmation.content":
    "¿Estás seguro de que quieres eliminar esta información adicional?",
  "structure.notes.actions.deprecated.confirmation.header":
    "Archivar información adicional",
  "structure.notes.actions.deprecated.confirmation.content":
    "¿Estás seguro de que quieres archivar esta información adicional?",
  "tabs.dd.extra_info": "Información adicional",
  "tabs.dd.notes": "Información adicional",
  //Plan de remediación
  "remediation.actions.create": "Crear plan de acción",
  "remediation.actions.delete.confirmation.content":
    "Se va a eliminar el plan de acción. ¿Está seguro?",
  "remediation.actions.delete.confirmation.header": "Eliminar plan de acción",
  "remediation.actions.edit": "Editar plan de acción",
  "template.scope.remediation": "Plan de acción",
  "templates.tabs.remediation": "Plan de acción",
  "permission.manage_remediations": "Gestionar planes de acción",
  "tabs.dq.ruleImplementationResult.remediationPlan": "Plan de acción",
  remediation: "Plan de acción",
  "quality.actions.unsubcribe.tooltip": "Editar suscripción",
  "quality.df_name": "Plantilla",
  "quality.updated_at": "Fecha de actualización",
  "filtersGrid.field.modifier.cast_as_timestamp": "Convertir a timestamp",
  "filtersGrid.field.modifier.cast_as_date": "Formato de fecha",
  "filtersGrid.field.modifier.to_string": "Convertir a texto",
  "filtersGrid.field.modifier.substring": "Subcadena",
  "filtersGrid.field.modifier.substring.start": "Posición de inicio",
  "filtersGrid.field.modifier.substring.end": "Posición de fin",
  "filtersGrid.field.modifier.concat": "Concatenar",
  "filtersGrid.field.modifier.concat.with_fields": "Campos"
};
