import _ from "lodash/fp";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { linkTo } from "@truedat/core/routes";
import { linksDecorator } from "@truedat/dd/selectors/getStructureFieldColumns";

const translateDecoratorWithDefault = (text, default_text) => (
  <FormattedMessage id={text} defaultMessage={default_text} />
);

const structureTypeDecorator = field =>
  translateDecoratorWithDefault(`structure.type.${field}`, field);

const relationTypeDecorator = field =>
  translateDecoratorWithDefault(`relation.type.${field}`, field);

const linkToStructureDecorator = ({
  structure: { data_structure_id: id, name }
}) => <Link to={linkTo.STRUCTURE({ id })}>{name}</Link>;

export const initialState = [
  {
    name: "name",
    fieldSelector: _.pick(["structure.data_structure_id", "structure.name"]),
    fieldDecorator: linkToStructureDecorator
  },
  {
    name: "type",
    fieldSelector: _.path("structure.type"),
    fieldDecorator: structureTypeDecorator
  },
  {
    name: "relation_type",
    fieldSelector: _.path("relation_type.name"),
    fieldDecorator: relationTypeDecorator
  },
  {
    name: "links",
    fieldDecorator: linksDecorator
  }
];

export const structureRelationColumns = (state = initialState) => state;
