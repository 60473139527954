import {
  SEARCH,
  SEARCH_RESULTS,
  SEARCH_CONCEPTS,
  SEARCH_STRUCTURES
} from "@truedat/core/routes";

const initialState = {
  [SEARCH]: ["concepts", "structures"],
  [SEARCH_RESULTS]: ["concepts", "structures"],
  [SEARCH_CONCEPTS]: ["concepts"],
  [SEARCH_STRUCTURES]: ["structures"]
};

export const searchIndexes = (state = initialState) => state;
