export default {
  "filters.comisario": "Data Owner",
  "filters.criticidad": "Criticality",
  "filters.gdpr": "GPDR",
  "filters.motivo_criticidad": "Criticity reason",
  "filters.periodicidad": "Periodicity",
  "filters.resp_tecnico": "Technical Responsible",
  "filters.soporte": "Technological Support",
  "filters.tipo_aplicacion": "Application type",
  "filters.tipo_dato": "Data type",
  "filters.agrupacion": "Information Group",
  "filters.calculan": "Calculated byr",
  "filters.confidencialidad": "Confidentiality",
  "filters.pais": "Country",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.domain": "Functional field",
  "filters.dominio_informacion": "Management Information",
  "filters.fase1": "DWH Phase I",
  "filters.functional_owner": "Functional Owner",
  "filters.idioma": "Language",
  "filters.naturaleza": "Nature",
  "filters.sensible": "Sensitive",
  "filters.sujeto_gdpr": "GDPR Compliance",
  "filters.temporalidad": "Temporal aggregation",
  "filters.tipo": "Concept Type",
  "filters._confidential": "Confidential",

  "concepts.props.type": "Concept type",
  "concepts.props.gdpr": "GDPR",
  "concepts.props.comisario": "Data Owner",
  "concepts.props.ambito": "Functional Scopel",

  "ruleImplementations.props.dataset": "Structure",
  "ruleImplementation.result.evidences": "Copy Evidences to Clipboard",

  "login.alternative": "Alternative login",
  "login.description": "Data Governance",
  "login.form.actions.saml_idp.login": "Corporate Login",
  "appSetup.welcome.message": "Welcome to Truedat",
  "appSetup.configuration.message":
    "It seems that everything has been installed correctly. Let's begin configuring application so that we can start using it after a few simple steps",
  "appSetup.begin.button": "Begin",
  "quality.actions.unsubcribe.tooltip": "Edit subscription",
  "quality.df_name": "Template",
  "quality.updated_at": "Updated Date",
  "filtersGrid.field.modifier.cast_as_timestamp": "Cast as timestamp",
  "filtersGrid.field.modifier.cast_as_date": "Date format",
  "filtersGrid.field.modifier.to_string": "To string",
  "filtersGrid.field.modifier.substring": "Substring",
  "filtersGrid.field.modifier.substring.start": "String start position",
  "filtersGrid.field.modifier.substring.end": "String end position",
  "filtersGrid.field.modifier.concat": "Concatenate",
  "filtersGrid.field.modifier.concat.with_fields": "Fields"
};
