import _ from "lodash/fp";

const relativeDates = ["D-0", "D-1", "M-1", "M-2", "Y-0", "Y-1"];

const initialState = {
  any: {
    operators: [
      { name: "unique", scope: "validation" },
      { name: "not_empty" },
      { name: "empty" },
      {
        group: "references",
        name: "references",
        value_type: "field",
        value_type_filter: "any",
        scope: "validation"
      },
      {
        group: "references",
        name: "not_references",
        value_type: "field",
        value_type_filter: "any",
        scope: "validation"
      },
      {
        name: "meets_criteria",
        value_type: "string",
        scope: "validation"
      }
    ]
  },
  boolean: {
    operators: [{ name: "is_true" }, { name: "is_false" }]
  },
  number: {
    operators: [
      { group: "eq", name: "eq", value_type: "number" },
      { group: "eq", name: "eq", value_type: "field" },
      { group: "not_eq", name: "not_eq", value_type: "number" },
      { group: "not_eq", name: "not_eq", value_type: "field" },
      { group: "gt", name: "gt", value_type: "number" },
      { group: "gt", name: "gt", value_type: "field" },
      { group: "lt", name: "lt", value_type: "number" },
      { group: "lt", name: "lt", value_type: "field" },
      { group: "gte", name: "gte", value_type: "number" },
      { group: "gte", name: "gte", value_type: "field" },
      { group: "lte", name: "lte", value_type: "number" },
      { group: "lte", name: "lte", value_type: "field" },
      {
        group: "between",
        name: "between",
        value_type: "number",
        arity: 2
      },
      {
        group: "between",
        name: "between",
        value_type: "field",
        arity: 2
      },
      {
        name: "in_list",
        value_type: "string_list"
      },
      {
        name: "not_in_list",
        value_type: "string_list"
      }
    ]
  },
  string: {
    operators: [
      { group: "eq", name: "eq", value_type: "string" },
      { group: "eq", name: "eq", value_type: "field" },
      { group: "not_eq", name: "not_eq", value_type: "string" },
      { group: "not_eq", name: "not_eq", value_type: "field" },
      { group: "length", name: "length_gt", value_type: "number" },
      { group: "length", name: "length_lt", value_type: "number" },
      {
        group: "length",
        name: "length_gte",
        value_type: "number"
      },
      {
        group: "length",
        name: "length_lte",
        value_type: "number"
      },
      {
        group: "length",
        name: "length_between",
        value_type: "number",
        arity: 2
      },
      { name: "contains", value_type: "string" },
      {
        name: "not_contains",
        value_type: "string"
      },

      {
        name: "starts_with",
        value_type: "string"
      },
      { name: "ends_with", value_type: "string" },
      {
        name: "in_list",
        value_type: "string_list"
      },
      {
        name: "not_in_list",
        value_type: "string_list"
      },
      {
        name: "format_of",
        group: "format",
        value_type: "string",
        fixed_values: [
          "email",
          "phone",
          "number",
          "decimal",
          "date",
          "timestamp",
          "DNI"
        ],
        scope: "validation"
      },
      {
        name: "regex_format",
        value_type: "string"
      }
    ]
  },
  date: {
    operators: [
      { group: "eq", name: "eq", value_type: "date" },
      { group: "eq", name: "eq", value_type: "field" },
      { group: "not_eq", name: "not_eq", value_type: "date" },
      { group: "not_eq", name: "not_eq", value_type: "field" },
      { group: "gt", name: "gt", value_type: "date" },
      { group: "gt", name: "gt", value_type: "field" },
      { group: "gte", name: "gte", value_type: "date" },
      { group: "gte", name: "gte", value_type: "field" },
      { group: "lt", name: "lt", value_type: "date" },
      { group: "lt", name: "lt", value_type: "field" },
      { group: "lte", name: "lte", value_type: "date" },
      { group: "lte", name: "lte", value_type: "field" },
      {
        name: "between",
        value_type: "date",
        arity: 2
      },
      {
        group: "eq",
        name: "eq_var",
        value_type: "string",
        fixed_values: relativeDates
      },
      {
        group: "lt",
        name: "lt_var",
        value_type: "string",
        fixed_values: relativeDates
      },
      {
        group: "lte",
        name: "lte_var",
        value_type: "string",
        fixed_values: relativeDates
      },
      {
        group: "gt",
        name: "gt_var",
        value_type: "string",
        fixed_values: relativeDates
      },
      {
        group: "gte",
        name: "gte_var",
        value_type: "string",
        fixed_values: relativeDates
      },
      {
        name: "variation_on_count",
        value_type: "string",
        fixed_values: relativeDates,
        scope: "validation",
        arity: 2
      }
    ]
  },
  timestamp: {
    operators: [
      { group: "eq", name: "eq", value_type: "timestamp" },
      { group: "eq", name: "eq", value_type: "field" },
      {
        group: "not_eq",
        name: "not_eq",
        value_type: "timestamp"
      },
      { group: "not_eq", name: "not_eq", value_type: "field" },

      { group: "gt", name: "gt", value_type: "timestamp" },
      { group: "gt", name: "gt", value_type: "field" },
      {
        group: "gte",
        name: "gte",
        value_type: "timestamp"
      },
      { group: "gte", name: "gte", value_type: "field" },

      { group: "lt", name: "lt", value_type: "timestamp" },
      { group: "lt", name: "lt", value_type: "field" },
      {
        group: "lte",
        name: "lte",
        value_type: "timestamp"
      },
      { group: "lte", name: "lte", value_type: "field" },
      {
        name: "between",
        value_type: "timestamp",
        arity: 2
      }
    ]
  }
};

export const ruleImplementationOperators = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
