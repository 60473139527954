import _ from "lodash/fp";
import React from "react";
import { FormattedMessage } from "react-intl";

const linkDecorator = evidences =>
  evidences ? (
    <a
      title="Copy to Clipboard"
      onClick={() => {
        navigator.clipboard.writeText("file:" + evidences);
      }}
    >
      <FormattedMessage
        id="ruleImplementation.result.evidences"
        defaultMessage="Copiar Link Evidencias"
      />
    </a>
  ) : (
    ""
  );

const initialState = [
  {
    name: "cmesano",
    fieldSelector: _.path("params.cmesano")
  },
  {
    name: "cbloqfun",
    fieldSelector: _.path("params.cbloqfun")
  },
  {
    name: "evidences",
    fieldSelector: _.path("params.evidences"),
    fieldDecorator: linkDecorator
  }
];

export const ruleResultsColumns = (state = initialState) => state;
