import _ from "lodash/fp";
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DateTime from "@truedat/core/components/DateTime";
import { defaultImplementationColumns } from "@truedat/dq/selectors";
import RuleImplementationLink from "@truedat/dq/components/RuleImplementationLink";
import { Link } from "react-router-dom";
import { linkTo } from "@truedat/core/routes";

const translateDecorator = text => <FormattedMessage id={text} />;

const RuleLink = ({ id, name }) =>
  id ? <Link to={linkTo.RULE({ id })}>{_.truncate({})(name)}</Link> : null;

const defaultImplementationColumnsWithoutI_key = _.filter(
  _.negate(_.propEq("name", "implementation_key"))
)(defaultImplementationColumns);

const defaultImplementationColumnsWithoutRule = _.filter(
  _.negate(_.propEq("name", "rule"))
)(defaultImplementationColumnsWithoutI_key);

const defaultImplementationColumnsWithoutStatus = _.filter(
  _.negate(_.propEq("name", "status"))
)(defaultImplementationColumnsWithoutRule);

const defaultImplementationColumnsWithWidthOne = [
  {
    name: "implementation_key",
    fieldSelector: _.pick(["id", "implementation_key", "rule_id"]),
    fieldDecorator: RuleImplementationLink,
    sort: { name: "implementation_key.raw" },
    width: 1
  },
  {
    name: "rule",
    fieldSelector: ({ rule, rule_id: id }) => ({ id, name: rule?.name }),
    sort: { name: "rule.name.raw" },
    fieldDecorator: RuleLink,
    width: 1
  },
  {
    name: "status",
    fieldSelector: ({ status }) =>
      translateDecorator(`ruleImplementation.status.${status}`),
    sort: { name: "status" },
    width: 1
  }
];

RuleLink.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string
};

export const ruleImplementationColumns = () => {
  return _.concat(
    defaultImplementationColumnsWithWidthOne,
    defaultImplementationColumnsWithoutStatus
  );
};
