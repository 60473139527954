import _ from "lodash/fp";
import { lowerDeburr } from "@truedat/core/services/sort";

const typeList = [
  "Project",
  "Workspace",
  "Model",
  "Submodel",
  "Folder",
  "file_folder",
  "worksheet",
  "Document",
  "Report",
  "DATA",
  "Calculated Table",
  "Table",
  "File",
  "Metric",
  "Measure",
  "Attribute",
  "Column",
  "Calculated Column"
];

const toWeightedMap = () => {
  const weightedList = typeList.map((type, i) => ({ [type]: i }));
  return _.reduce((acc, value) => _.merge(value)(acc), {})(weightedList);
};

const mapWeights = toWeightedMap();
const weightFromMap = type => _.prop(type)(mapWeights);
const weightOrDefault = weight => (_.isNil(weight) ? _.size(typeList) : weight);
const orderCriteria = ({ metadata: { order } }) => order && _.toInteger(order);
const typeCriteria = ({ type }) =>
  _.flow(lowerDeburr, weightFromMap, weightOrDefault)(type);
const nameCriteria = ({ name }) => name && lowerDeburr(name).trim();

const initialState = [typeCriteria, orderCriteria, nameCriteria];

export const structureSortingCriteria = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
