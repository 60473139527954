import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ErrorBoundary, ScrollToTop } from "@truedat/core/components";
import { LangProvider } from "@truedat/core/i18n/components";
import { SWRConfig } from "swr";
import { store, client, swrConfig } from "./store";
import defaultMessages from "./messages";
import { AppRoutes } from "./components";

const App = () => (
  <Provider store={store}>
    <LangProvider defaultMessages={defaultMessages}>
      <Router>
        <ScrollToTop>
          <ApolloProvider client={client}>
            <SWRConfig value={swrConfig}>
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </SWRConfig>
          </ApolloProvider>
        </ScrollToTop>
      </Router>
    </LangProvider>
  </Provider>
);

export default App;
