import _ from "lodash/fp";
import React from "react";
import Moment from "react-moment";
import { defaultRuleColumns } from "@truedat/dq/selectors";

const templateColumn = {
  name: "df_name",
  fieldSelector: _.prop("df_name"),
  sort: { name: "df_name.raw" },
  width: 3
};

const defaultRuleColumnsWithoutUpdatedAt = _.filter(
  _.negate(_.propEq("name", "updated_at"))
)(defaultRuleColumns());

const dateDecorator = date => (
  <Moment locale="es" date={date} format="YYYY-MM-DD HH:mm" />
);

const updatedAt = [
  {
    name: "updated_at",
    width: 3,
    sort: { name: "updated_at" },
    fieldDecorator: dateDecorator
  }
];

const initialState = [
  ...defaultRuleColumnsWithoutUpdatedAt,
  ...updatedAt,
  templateColumn
];

export const rulesColumns = (state = initialState) => state;
