/* eslint-disable no-undef */
import React from "react";
import { connect } from "react-redux";
import ResponsiveEmbed from "react-responsive-embed";
import PropTypes from "prop-types";
import { UserDomainsFilter } from "@truedat/auth/users/components";
import { signConfiguration } from "@truedat/cx/routines";

export const DashboardMetabase = ({
  action,
  configurationToken,
  dashboardConfig
}) => {
  const url = dashboardConfig?.content?.metabase_url;

  const src = configurationToken
    ? `${url}/embed/dashboard/${configurationToken}#bordered=false&titled=true`
    : null;
  return (
    <>
      <UserDomainsFilter action={action} />
      {src ? <ResponsiveEmbed src={src} scrolling={"yes"} /> : null}
    </>
  );
};

const mapStateToProps = ({ configurationToken }) => ({ configurationToken });

DashboardMetabase.propTypes = {
  dashboardConfig: PropTypes.object,
  configurationToken: PropTypes.string,
  action: PropTypes.string
};

export default connect(mapStateToProps, { signConfiguration })(
  DashboardMetabase
);
