import _ from "lodash/fp";
import { lowerDeburr } from "@truedat/core/services/sort";

const lowerDeburrPath = (field, key) => _.flow(_.path(key), lowerDeburr)(field);

const nameCriteria = field => lowerDeburrPath(field, "structure.name");
const typeCriteria = field => lowerDeburrPath(field, "structure.type");

const initialState = [typeCriteria, nameCriteria];

export const relationSortingCriteria = (state = initialState) => state;
