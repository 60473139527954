import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "styles/theme/semantic.less";
import "styles/bg/components.less";
import "styles/dd/components.less";
import "styles/df/components.less";
import "styles/dq/components.less";
import "styles/se/components.less";
import "styles/lm/components.less";
import "styles/text.less";
import "styles/dq.less";
import "styles/dl.less";

ReactDOM.render(<App />, document.getElementById("app"));
