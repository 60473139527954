import coreEs from "@truedat/core/messages/es";
import coreEn from "@truedat/core/messages/en";

import auditEs from "@truedat/audit/messages/es";
import auditEn from "@truedat/audit/messages/en";

import authEs from "@truedat/auth/messages/es";
import authEn from "@truedat/auth/messages/en";

import bgEs from "@truedat/bg/messages/es";
import bgEn from "@truedat/bg/messages/en";

import cxEs from "@truedat/cx/messages/es";
import cxEn from "@truedat/cx/messages/en";

import ddEs from "@truedat/dd/messages/es";
import ddEn from "@truedat/dd/messages/en";

import dfEs from "@truedat/df/messages/es";
import dfEn from "@truedat/df/messages/en";

import dqEs from "@truedat/dq/messages/es";
import dqEn from "@truedat/dq/messages/en";

import seEs from "@truedat/se/messages/es";
import seEn from "@truedat/se/messages/en";

import lmEs from "@truedat/lm/messages/es";
import lmEn from "@truedat/lm/messages/en";

import es from "./es";
import en from "./en";

const combinedEs = {
  ...coreEs,
  ...authEs,
  ...auditEs,
  ...bgEs,
  ...cxEs,
  ...ddEs,
  ...dfEs,
  ...dqEs,
  ...seEs,
  ...lmEs,
  ...es
};

const combinedEn = {
  ...coreEn,
  ...authEn,
  ...auditEn,
  ...bgEn,
  ...cxEn,
  ...ddEn,
  ...dfEn,
  ...dqEn,
  ...seEn,
  ...lmEn,
  ...en
};

export default {
  es: combinedEs,
  en: combinedEn
};
