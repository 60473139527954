import _ from "lodash/fp";
import { defaultStructureProperties } from "@truedat/dd/selectors";
import React from "react";
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import moment from "moment";

const defaultStructuresPropertiesWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureProperties);

const formatBytes = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (_.isNil(bytes)) return "";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return i === 0
    ? `${bytes} ${sizes[i]}`
    : `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const dateDecorator = date => {
  if (date) {
    return (
      <Moment
        locale="es"
        date={moment.utc(date).utcOffset("+0200")}
        format="YYYY-MM-DD HH:mm"
      />
    );
  }
};

const initialState = [
  ...defaultStructuresPropertiesWithoutGroup,
  {
    name: "last_modified",
    fieldSelector: _.path("metadata.last_modified"),
    fieldDecorator: dateDecorator
  },
  {
    name: "size",
    fieldSelector: _.path("metadata.size"),
    fieldDecorator: formatBytes
  },
  {
    name: "filename",
    width: 8,
    fieldSelector: _.path("metadata.filename")
  }
];

export const structureProperties = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
