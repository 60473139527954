import _ from "lodash/fp";
import { defaultStructureFieldColumns } from "@truedat/dd/selectors";

const customStructureFieldsColumns = [
  { name: "length" },
  { name: "primary_key" }
];

export const structureFieldsColumns = () => {
  return _.concat(defaultStructureFieldColumns, customStructureFieldsColumns);
};
