import { createRelation } from "@truedat/lm/routines";
import { clearRedirect } from "@truedat/core/routines";

const initialState = "";

export const lmRedirect = (state = initialState, { type, meta }) => {
  switch (type) {
    case clearRedirect.TRIGGER:
      return initialState;
    case createRelation.SUCCESS:
      const { redirectUrl } = meta;
      return redirectUrl;
    default:
      return state;
  }
};
