import React from "react";
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";

const iconDecorator = field => (field > 0 ? <Icon name="check circle" /> : "");

const dateDecorator = date => <Moment locale="es" date={date} />;

const translateDecorator = text => <FormattedMessage id={text} />;

const initialState = {
  concepts: [
    {
      name: "type_label",
      width: 2,
      sort: { name: "template.label.raw" }
    },
    {
      name: "name",
      width: 4,
      sort: { name: "name.raw" }
    },
    {
      name: "status",
      fieldDecorator: field => translateDecorator(`concepts.status.${field}`),
      width: 2,
      sort: { name: "status" }
    },
    {
      name: "domain",
      header: "ambito",
      fieldSelector: ({ domain }) => {
        return (domain && domain.name) || "";
      },
      width: 2,
      sort: { name: "domain.name.raw" }
    },
    {
      name: "content",
      header: "comisario",
      fieldSelector: ({ content }) => {
        return (content && content.comisario) || "";
      },
      width: 3,
      sort: { name: "content.comisario.raw" }
    },
    {
      name: "rule_count",
      fieldDecorator: iconDecorator,
      textAlign: "center",
      width: 1,
      sort: { name: "rule_count" }
    },
    {
      name: "link_count",
      fieldDecorator: iconDecorator,
      textAlign: "center",
      width: 1,
      sort: { name: "link_count" }
    },
    {
      name: "content",
      header: "gdpr",
      fieldSelector: ({ content }) => {
        return (content && content.gdpr) || "";
      },
      textAlign: "center",
      width: 1,
      sort: { name: "content.gdpr.raw" }
    }
  ]
};

export const conceptsColumns = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
